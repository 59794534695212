<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card>
      <v-card-title class="headline">
        <span>
          {{title}}
        </span>
      </v-card-title>

      <v-card-text>
        <slot name="body">
        </slot>
        <v-alert
          v-if="warning.length > 0"
          border="left"
          colored-border
          type="warning"
          elevation="2"
          class="ma-4"
          icon="mdi-alert"
        >
          {{ warning }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="showDialog = false"
          :loading="isBusy"
        >
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="confirmClicked"
          :loading="isBusy"
        >
          {{ $t('common.buttons.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    /**
     * The title to display in the dialog.
     */
    title: { type: String, default: '', required: true },
    /**
     * The warning to display in the dialog.
     */
    warning: { type: String, default: '' },
    /**
     * Whether the dialog is currently busy.
     */
    isBusy: { type: Boolean, default: false }
  },
  emits: ['confirmed'],
  computed: {
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    }
  },
  methods: {
    confirmClicked () {
      this.$emit('confirmed', true)
    }
  }
}
</script>
