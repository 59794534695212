<template>
  <span v-bind="$attrs">
    {{ displayText }}
  </span>
</template>

<script>
import moment from 'moment-timezone'

export default {
  props: {
    date: { required: true },
    format: { type: String, default: 'YYYY-MM-DD HH:mm' }
  },
  computed: {
    displayText () {
      if (this.date == null || this.date === undefined) return '-'

      // Moment will automagically apply the current default timezone,
      // which was set with moment.tz.setDefault(timezone).
      return moment(this.date).format(this.format)
    }
  }
}
</script>
