<template>
    <v-dialog
      v-model="value"
      persistent
      width="75%"
      max-width="750px"
      overlay-opacity="0.75"
    >
      <v-card
        :color="color"
        dark
        elevation="3"
      >
        <v-card-title>
          <slot name="title">
            Hol' up! ⌚
          </slot>
        </v-card-title>

        <v-card-text>
          <v-progress-linear
            v-model="progress"
            :color="darkenedColor"
            height="50"
          >
            <slot name="bar-content">
              <strong>{{ Math.ceil(progress) }}%</strong>
            </slot>
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    /**
     * The current progress of the linear progress bar, must be in range 0-100.
     */
    progress: { type: Number, required: true },
    /**
     * The general color of the progress dialog. Set this to a vuetify/material design color.
     * Examples: teal, primary, indigo, secondary, success, lime.
     */
    color: { type: String, default: 'primary' }
  },
  computed: {
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    },
    darkenedColor () {
      return `${this.color} darken-4`
    }
  }
}
</script>
