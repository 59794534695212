<template>
  <v-dialog v-model="showDialog" max-width="700px">
    <ProgressDialog
      v-model="isUploading"
      :progress="progress"
      color="teal"
    >
      <template #title>
        {{ $t('sessions.upload-dialog.progress-title') }}<v-spacer></v-spacer>({{ filesUploadedCount }} / {{ filesToUploadCount }})
      </template>
    </ProgressDialog>

    <v-card>
      <v-card-title class="headline">
          {{ $t('sessions.upload-dialog.title') }}
      </v-card-title>

      <v-card-text>
        <v-alert
          border="right"
          colored-border
          type="info"
          elevation="2"
          class="ma-4"
          icon="mdi-information"
        >
         {{ $t('sessions.upload-dialog.help-text') }}
        </v-alert>
        <ErrorsDisplay
          :errors="errors"
          class="ma-4"
        />

        <v-file-input
          v-model="files"
          :label="$t('sessions.upload-dialog.file-input-label')"
          accept=".json"
          chips
          counter
          multiple
          truncate-length="25"
          clearable
          :disabled="isUploading"
        >
          <template #selection="{index, text, file}">
            <v-tooltip
              v-if="index < maxFilesDisplayed"
              top
            >
              <template #activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  label
                >
                  {{ text }}
                </v-chip>
              </template>
              <span>{{ file.name }}</span>
            </v-tooltip>
            <span
              v-else-if="index === maxFilesDisplayed"
              class="overline mx-2"
            >
              {{ $tc('sessions.upload-dialog.file-list-abbreviation', files.length - maxFilesDisplayed) }}
            </span>
          </template>
        </v-file-input>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="showDialog = false"
          :loading="isUploading"
        >
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="uploadClicked"
          :loading="isUploading"
          :disabled="this.files.length <= 0"
        >
          {{ $t('sessions.upload-dialog.upload-button') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import ErrorsDisplay from '@/components/ErrorsDisplay'
import ProgressDialog from '@/components/dialogs/ProgressDialog'

export default {
  components: { ErrorsDisplay, ProgressDialog },
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    maxFilesDisplayed: { type: Number, default: 2 }
  },
  /**
   * itemUploaded: When a single item is uploaded.
   * uploadFinished: When all items are done uploading
   */
  emits: ['input', 'itemUploaded', 'uploadFinished'],
  data () {
    return {
      isUploading: false,
      files: [],
      filesToUploadCount: 0,
      finishedFileIndexes: [],
      errors: []
    }
  },
  computed: {
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) {
        this.$emit('input', newValue)
        this.errors = []
        this.files = []
      }
    },
    filesUploadedCount () {
      return this.finishedFileIndexes.length
    },
    progress () {
      return this.filesToUploadCount === 0 || this.filesUploadedCount === 0
        ? 0
        : Math.ceil((this.filesUploadedCount / this.filesToUploadCount) * 100)
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    async readFileAsync (file) {
      return await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = () => reject(new Error(this.$t('errors.loading-file-failed')))
        reader.readAsText(file)
      })
    },
    async uploadSession (fileIndex) {
      const file = this.files[fileIndex]
      const json = await this.readFileAsync(file)
      try {
        const response = await axios.post('sessions', JSON.parse(json))
        this.finishedFileIndexes.push(fileIndex)
        // Tell parent component a new session was uploaded.
        this.$emit('itemUploaded', response.data)
      } catch (error) {
        // Ignore 409 (conflict) responses, they mean the session already exists.
        if (error.response && error.response.status === 409) {
          this.finishedFileIndexes.push(fileIndex)
        } else {
          throw error
        }
      }
    },
    async uploadSessions () {
      try {
        const uploads = []
        for (let i = 0; i < this.files.length; i++) {
          uploads.push(this.uploadSession(i))
        }
        await Promise.all(uploads)
      } finally {
        if (this.finishedFileIndexes.length === this.files.length) {
          // Show a message indicating the upload succeeded.
          this.showSnackbar({
            role: 'success',
            messages: [this.$tc('sessions.upload-dialog.upload-success-message', this.files.length)],
            duration: 5000
          })
          // If all were uploaded succesfully, we can clear the entire file list.
          this.files = []
        } else {
          // Show a message indicating the upload failed.
          this.showSnackbar({
            role: 'error',
            messages: [this.$tc('sessions.upload-dialog.upload-failed-message', this.files.length)],
            duration: 5000
          })
          // Remove the successfully uploaded files from the file input:
          // When some items failed to upload, only those that failed will remain in the file input.
          // Order the indexes descending, so removing an item won't affect lower indexes.
          const sortedFileIndexes = this.finishedFileIndexes.sort().reverse()
          for (let i = 0; i < sortedFileIndexes.length; i++) {
            this.files.splice(sortedFileIndexes[i], 1)
          }
        }
      }
    },
    async uploadClicked () {
      this.filesToUploadCount = this.files.length
      this.finishedFileIndexes = []
      this.errors = []
      this.isUploading = true

      try {
        // Upload all items.
        await this.uploadSessions()
        // Close the dialog.
        this.showDialog = false
      } catch (error) {
        this.errors = error.response && error.response.data ? [error.response.data] : [error]
      } finally {
        this.isUploading = false
        this.$emit('uploadFinished', this.finishedFileIndexes.length)
      }
    }
  }
}
</script>
