<template>
  <span v-if="system == null">
    -
  </span>
  <router-link v-else :to="detailsUrl">
    {{ system.name }}
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['systemId'],
  computed: {
    ...mapGetters('systems', ['getSystemById']),
    system () {
      return this.getSystemById(this.systemId)
    },
    detailsUrl () {
      return `/systems/${this.systemId}`
    }
  }
}
</script>
