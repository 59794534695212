<template>
  <v-row>
    <!-- Connecting time -->
    <v-col cols="12" sm="4">
      <div>
        <h4>{{ $t('sessions.table.expansion.connecting-time-label') }}</h4>
        <v-skeleton-loader
          v-if="isLoading"
          type="text"
          max-width="100px"
        />
        <span v-else-if="timeConnecting == null">
          -
        </span>
        <TimeSpanDisplay
          v-else
          :time-span="timeConnecting"
        />
      </div>
    </v-col>

    <!-- Fuelling time -->
    <v-col cols="12" sm="4">
      <div>
        <h4>{{ $t('sessions.table.expansion.fuelling-time-label') }}</h4>
        <v-skeleton-loader
          v-if="isLoading"
          type="text"
          max-width="100px"
        />
        <span v-else-if="timeFuelling == null">
          -
        </span>
        <TimeSpanDisplay
          v-else
          :time-span="timeFuelling"
        />
      </div>
    </v-col>

    <!-- Disconnecting time -->
    <v-col cols="12" sm="4">
      <div>
        <h4>{{ $t('sessions.table.expansion.disconnecting-time-label') }}</h4>
        <v-skeleton-loader
          v-if="isLoading"
          type="text"
          max-width="100px"
        />
        <span v-else-if="timeDisconnecting == null">
          -
        </span>
        <TimeSpanDisplay
          v-else
          :time-span="timeDisconnecting"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import TimeSpanDisplay from '@/components/TimeSpanDisplay'

export default {
  components: { TimeSpanDisplay },
  props: {
    session: { type: Object, required: true }
  },
  data () {
    return {
      eventData: [],
      isLoading: true
    }
  },
  computed: {
    timeConnecting () {
      return this.getEventDataByName(this.eventData, 'time-connecting')
    },
    timeFuelling () {
      return this.getEventDataByName(this.eventData, 'time-fuelling')
    },
    timeDisconnecting () {
      return this.getEventDataByName(this.eventData, 'time-disconnecting')
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    getEventDataByName (eventData, name) {
      const result = eventData.find(function (item) {
        return item.name === name
      })
      return result ? result.value : null
    },
    async fetchEventData (sessionId) {
      return await axios.get(`sessions/${sessionId}/eventData`)
    }
  },
  watch: {
    session: {
      immediate: true,
      async handler (newVal, oldVal) {
        try {
          this.isLoading = true
          const eventDataResponse = await this.fetchEventData(newVal.id)
          this.eventData = eventDataResponse.data
        } catch (error) {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('errors.loading-data-failed')],
            duration: 5000
          })
        } finally {
          this.isLoading = false
        }
      }
    }
  }
}
</script>
