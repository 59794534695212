<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card :loading="isLoading">
      <v-form>
        <!-- Title -->
        <v-card-title>
          <span class="headline">
            {{ $t('sessions.dialog.edit-title') }}
          </span>
        </v-card-title>

        <!-- Content -->
        <v-card-text>
          <v-container>
            <v-row>
              <!-- Error cause -->
              <v-col cols="12">
                <v-select
                  v-model="selectedErrorCauseId"
                  :label="$t('sessions.dialog.error-cause-label')"
                  class="pa-0 ma-0"
                  clearable
                  item-text="description"
                  item-value="id"
                  :items="assignableErrorCauses"
                  :error-messages="validationErrors['errorCauseId']"
                  :error-count="3"
                />
              </v-col>

              <!-- Comment -->
              <v-col cols="12">
                <v-textarea
                  v-model="comment"
                  :label="$t('sessions.dialog.comment-label')"
                  rows="3"
                  auto-grow
                  counter
                  :error-messages="validationErrors['comment']"
                  :error-count="3"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Buttons -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
            :loading="isSaving"
            :disabled="isLoading"
          >
            {{ $t('common.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    session: { type: Object, default: null }
  },
  emits: ['itemCreated', 'itemUpdated'],
  data () {
    return {
      description: '',
      selectedErrorCauseId: null,
      assignableErrorCauses: [],
      comment: '',
      isSaving: false,
      throttling: false,
      validationErrors: {},
      isLoading: true
    }
  },
  computed: {
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('errorCauses', ['fetchErrorCausesAssignableToSystem']),
    close () {
      this.showDialog = false
    },
    async save () {
      this.isSaving = true
      this.validationErrors = {}

      try {
        // Send a request to update the item.
        const response = await this.updateExisiting()
        // Notify the dialog has saved the item.
        this.$emit('itemUpdated', response.data)
        // Show a message indicating the save succeeded.
        this.showSnackbar({
          role: 'success',
          messages: [this.$t('common.save-success-message', { entityName: this.$t('common.session') })],
          duration: 5000
        })
        // Close the dialog.
        this.close()
      } catch (error) {
        console.error(error)
        if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
          this.validationErrors = error.response.data.errors
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('common.save-failed-message', { entityName: this.$t('common.session') })],
            duration: 5000
          })
        }
      } finally {
        this.isSaving = false
      }
    },
    async updateExisiting () {
      const payload = {
        id: this.session.id,
        errorCauseId: this.selectedErrorCauseId,
        isArchived: this.session.isArchived,
        comment: this.comment
      }
      return await axios.put('sessions', payload)
    },
    async fetchErrorCauses () {
      this.isLoading = true
      try {
        const response = await this.fetchErrorCausesAssignableToSystem(this.session.systemId)
        this.assignableErrorCauses = response.data
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
        this.close()
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    // Watch for value (v-model) changes. Occurs when the dialog is opened/closed.
    value: {
      immediate: true,
      async handler (newVal, oldVal) {
        // Reset assignable error causes.
        this.assignableErrorCauses = []

        // Update the values if the dialog is being opened.
        if (newVal) {
          this.selectedErrorCauseId = this.session?.errorCauseId ? this.session.errorCauseId : null
          this.comment = this.session ? this.session.comment : ''
          this.validationErrors = {}
          // Fetches all error causes assignable to the system of this session when the dialog opens.
          await this.fetchErrorCauses()
        }
      }
    }
  }
}
</script>
