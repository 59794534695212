<template>
  <span v-if="days > 0">
    {{ days }}<span class="affix">d</span>
    {{ hours }}<span class="affix">h</span>
    {{ minutes }}<span class="affix">m</span>
  </span>
  <span v-else-if="hours > 0">
    {{ hours }}<span class="affix">h</span>
    {{ minutes }}<span class="affix">m</span>
    {{ seconds }}<span class="affix">s</span>
  </span>
  <span v-else-if="minutes > 0">
    {{ minutes }}<span class="affix">m</span>
    {{ seconds }}<span class="affix">s</span>
  </span>
  <span v-else-if="seconds > 0">
    {{ seconds }}<span class="affix">s</span>
  </span>
  <span v-else>
    {{ milliseconds }}<span class="affix">ms</span>
  </span>
</template>

<script>
export default {
  props: ['startTime', 'endTime'],
  computed: {
    differenceInMs () {
      const start = this.startTime instanceof Date ? this.startTime : new Date(this.startTime)
      const end = this.endTime instanceof Date ? this.endTime : new Date(this.endTime)
      return Math.abs(start - end)
    },
    days () {
      return Math.floor(this.differenceInMs / 86400000)
    },
    hours () {
      return Math.floor((this.differenceInMs % 86400000) / 3600000)
    },
    minutes () {
      return Math.floor(((this.differenceInMs % 86400000) % 3600000) / 60000)
    },
    seconds () {
      return Math.floor((((this.differenceInMs % 86400000) % 3600000) % 60000) / 1000)
    },
    milliseconds () {
      return Math.ceil((((this.differenceInMs % 86400000) % 3600000) % 60000) % 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.affix {
  margin-left: 1px;
  font-size: 0.75rem;
  opacity: 0.9;
}
</style>
