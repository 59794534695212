<template>
  <span v-if="hours > 0">
    {{ hours }}<span class="affix">h</span>
    {{ minutes }}<span class="affix">m</span>
    {{ seconds }}<span class="affix">s</span>
  </span>
  <span v-else-if="minutes > 0">
    {{ minutes }}<span class="affix">m</span>
    {{ seconds }}<span class="affix">s</span>
  </span>
  <span v-else-if="seconds > 0">
    {{ seconds }}<span class="affix">s</span>
  </span>
  <span v-else>
    <span v-if="milliseconds <= 0">&lt;</span>
    {{ milliseconds }}
    <span class="affix">ms</span>
  </span>
</template>

<script>
export default {
  props: {
    /**
     * A timespan in the way C# does it: '00:00:01.5761632'.
     */
    timeSpan: { type: String, required: true }
  },
  computed: {
    milliseconds () {
      const microOrNanoSeconds = this.timeSpan.split('.')[1]
      return parseInt(microOrNanoSeconds.substring(0, 3))
    },
    seconds () {
      const secondsAndMilliseconds = this.timeSpan.split(':')[2]
      return parseInt(secondsAndMilliseconds.split('.')[0])
    },
    minutes () {
      return parseInt(this.timeSpan.split(':')[1])
    },
    hours () {
      return parseInt(this.timeSpan.split(':')[0])
    }
  }
}
</script>

<style lang="scss" scoped>
.affix {
  margin-left: 1px;
  font-size: 0.75rem;
  opacity: 0.9;
}
</style>
