<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody>
        <tr
          v-for="data in eventData"
          :key="data.id"
        >
          <td>
            {{ data.name }}
          </td>
          <td>
            <EventDataDisplay :eventData="data" />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import EventDataDisplay from '@/components/EventDataDisplay'

export default {
  components: { EventDataDisplay },
  props: {
    eventData: { type: Array, required: true }
  }
}
</script>
