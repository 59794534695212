<template>
  <span v-if="vehicle == null">
    -
  </span>
  <router-link v-else :to="detailsUrl">
    {{ displayName }}
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    vehicleId: { type: Number, required: true }
  },
  computed: {
    ...mapGetters('vehicles', ['getVehicleById']),
    vehicle () {
      return this.getVehicleById(this.vehicleId)
    },
    displayName () {
      return this.vehicle.name ? this.vehicle.name : this.vehicle.uniqueIdentifier
    },
    detailsUrl () {
      return `/vehicles/${this.vehicleId}`
    }
  }
}
</script>
