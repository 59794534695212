<template>
  <v-icon :color="value ? trueColor : falseColor">
    {{ value ? trueIcon : falseIcon }}
  </v-icon>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    trueColor: { type: String, default: 'success' },
    falseColor: { type: String, default: 'error' },
    trueIcon: { type: String, default: 'mdi-check-bold' },
    falseIcon: { type: String, default: 'mdi-close-thick' }
  }
}
</script>
