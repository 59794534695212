<template>
  <v-tooltip v-if="quantity && quantity > 0" bottom>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs"
            v-on="on">
        <span>{{ displayQuantity }}</span>
        <span class="unit">{{ $t(unit.shortTranslationKey) }}</span>
      </span>
    </template>
    <span>{{ tooltipDisplayQuantity }}</span>
    <span class="unit">{{ $t(unit.longTranslationKey) }}</span>
  </v-tooltip>
  <span v-else>
    -
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    quantity: { type: Number, default: null },
    unit: { type: Object, required: true }
  },
  computed: {
    ...mapState('languages', ['currentLanguageCode']),
    quantityCorrectedForUnit () {
      return this.unit.convert(this.quantity)
    },
    tooltipDisplayQuantity () {
      return this.quantityCorrectedForUnit.toLocaleString(this.currentLanguageCode)
    },
    displayQuantity () {
      // Round to correct precision.
      const precision = this.unit.displayPrecision
      const multiplier = Math.pow(10, precision || 0)
      const roundedValue = Math.round(this.quantityCorrectedForUnit * multiplier) / multiplier
      return roundedValue.toLocaleString(roundedValue, {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.unit {
  margin-left: 3px;
  font-size: 0.75rem;
  opacity: 0.75;
}

/**
  Tooltips blend in with the background of a hovered row.
  This makes the tooltips a bit darker, so they are easier to see.
 */
.v-tooltip__content {
  background: black !important;
}
</style>
