export default {
  /**
   * Converts a software version to a readable string.
   * @param {Object} softwareVersion The software version to convert.
   * @returns {String} A readable software version.
   */
  versionToString: function (softwareVersion) {
    if (!softwareVersion) {
      return '-'
    }

    let preRelease = ''
    if (softwareVersion.preRelease && softwareVersion.preRelease.length > 0) {
      // If the pre-release doesn't start with a hyphen already...
      if (!softwareVersion.preRelease.startsWith('-')) {
        // Add the hyphen prefix.
        preRelease = `-${softwareVersion.preRelease}`
      } else {
        preRelease = softwareVersion.preRelease
      }
    }

    return `${softwareVersion.major}.${softwareVersion.minor}.${softwareVersion.patch}${preRelease}`
  }
}
