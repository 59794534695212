<template>
  <span v-if="driver == null">
    -
  </span>
  <router-link v-else :to="detailsUrl">
    {{ driver.name }}
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    driverId: { type: Number, default: null }
  },
  computed: {
    ...mapGetters('drivers', ['getDriverById']),
    driver () {
      return this.driverId == null ? null : this.getDriverById(this.driverId)
    },
    detailsUrl () {
      return `/drivers/${this.driverId}`
    }
  }
}
</script>
