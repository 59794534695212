<template>
    <!-- Bool -->
    <BooleanDisplay
      v-if="type === 5"
      @input="formattedValue"
    />
    <!-- TimeSpan -->
    <TimeSpanDisplay
      v-else-if="type === 4"
      :timeSpan="formattedValue"
    />
    <!-- Other -->
    <span v-else>
      {{ formattedValue }}
    </span>
</template>

<script>
import { mapState } from 'vuex'
import BooleanDisplay from '@/components/BooleanDisplay'
import TimeSpanDisplay from '@/components/TimeSpanDisplay'

export default {
  components: { BooleanDisplay, TimeSpanDisplay },
  props: {
    eventData: { type: Object, required: true }
  },
  computed: {
    ...mapState('languages', ['currentLanguageCode']),
    type () {
      return this.eventData.dataType
    },
    formattedValue () {
      // INT32 and INT64
      if (this.type === 0 || this.type === 1) {
        return this.eventData.value.toLocaleString(this.currentLanguageCode)
      }
      // Decimals
      if (this.type === 2) {
        const precision = this.eventData.value > 10 ? 2 : 3
        return this.eventData.value.toLocaleString(this.currentLanguageCode, {
          minimumFractionDigits: precision,
          maximumFractionDigits: precision
        })
      }

      return this.eventData.value
    }
  }
}
</script>
